import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaderResponse,
  HttpInterceptor,
  HttpProgressEvent,
  HttpRequest,
  HttpResponse,
  HttpSentEvent,
  HttpUserEvent,
} from '@angular/common/http';
import {catchError, EMPTY, filter, from, Observable, of, Subject, switchMap, take, throwError} from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  private isRefreshing: boolean = false;
  private refreshTokenSubject: Subject<string> = new Subject<string>();
  private isLoggingOut: boolean = false;
  private stopRequests: boolean = false;

  constructor(private authService: AuthService) {
  }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown> |
    HttpSentEvent |
    HttpHeaderResponse |
    HttpProgressEvent |
    HttpResponse<unknown> |
    HttpUserEvent<unknown>> {
    if (this.stopRequests) {
      return EMPTY;
    }

    if (!this.authService.isLoggedIn) {
      return next.handle(request)
        .pipe(catchError((exception: HttpErrorResponse) => {
          if (exception.status === 401) {
            this.authService.logout();
            return of();
          }

          return throwError(() => new HttpErrorResponse({
            headers: exception.headers,
            status: exception.status,
            statusText: exception.statusText,
            url: exception.url ?? undefined,
            error: exception.error,
          }));
        }));
    }

    request = this.addToken(request, this.authService.token);

    return next.handle(request)
      .pipe(catchError((exception: HttpErrorResponse) => {
        if (exception.status === 403 && this.authService.isTokenExpired) {
          return this.handleRefreshToken(request, next);
        }

        if (exception.status === 401) {
          this.handleLogoutOnce();
          this.stopRequests = true;
          return of();
        }

        return throwError(() => new HttpErrorResponse({
          headers: exception.headers,
          status: exception.status,
          statusText: exception.statusText,
          url: exception.url ?? undefined,
          error: exception.error,
        }));
      }));
  }

  private addToken(request: HttpRequest<unknown>, token: string | undefined): HttpRequest<unknown> {
    if (request.url.indexOf('accounts.zoho') === -1) {
      return request.clone({
        setHeaders: {
          'Authorization': `Bearer ${token}`,
        },
      });
    }
    return request;
  }

  handleRefreshToken(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next('');

      return from(this.authService.refreshToken())
        .pipe(
          switchMap(() => {
            this.isRefreshing = false;
            this.refreshTokenSubject.next(this.authService.token || '');
            return next.handle(this.addToken(request, this.authService.token));
          }),
          catchError(() => {
            this.authService.logout();
            this.stopRequests = true;
            return of();
          }),
        );
    }

    return this.refreshTokenSubject.pipe(
      filter((token: string) => !!token),
      take(1),
      switchMap((token: string) => next.handle(this.addToken(request, token))),
    );
  }

  private handleLogoutOnce() {
    if (!this.isLoggingOut) {
      this.isLoggingOut = true;
      this.authService.logout()
      setTimeout(() => (this.isLoggingOut = false), 4)
    }
  }

}
